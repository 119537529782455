<template>
  <div class="mt-8">
    <div class="d-flex mb-2">
      <label class="text-field-label">Time Slots</label>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="appbar__btn"
        height="35px"
        @click="createItem()"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Time Slot
      </v-btn>
    </div>
    <div
      class="mx-md-4"
      id="block-time-slot-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="timeSlotListValue"
        :options.sync="options"
        :loading="loading"
        :items-per-page="10"
        :server-items-length="timeSlotListValue.length"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :sort-by.sync="timeslotDefaultFilter"
        :sort-desc.sync="sortOrder"
        :no-data-text="noDataText"
      >
        <template v-slot:item.date="{ item }">
          {{ formatDate(item.start_time) }}
        </template>
        <template v-slot:item.start="{ item }">
          {{ formatTime(item.start_time) }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ formatTime(item.end_time) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="me-2" size="default" @click="editItem(item)">{{
            icons.edit
          }}</v-icon>
          <v-icon size="default" @click="deleteItem(item)">{{
            icons.delete
          }}</v-icon>
        </template>
        <template #no-data>
          {{ noDataText }}
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialogBlockSlotTime" max-width="600px">
      <v-form
        ref="formCreateBlockTimeSlot"
        class="bookable-block-time-slot-form__form"
        @submit.prevent="submitCreate"
      >
        <input type="hidden" :value="formBlockTimeSlot.id" name="id" />
        <v-card class="px-2 py-2">
          <v-card-title>
            <span class="text-h5 ms-3"
              >{{ formBlockTimeSlot.id ? 'Edit' : 'New' }} Time Slot</span
            >
          </v-card-title>
          <v-card-text class="pb-0 mb-6">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <DatetimePicker
                    v-model="formBlockTimeSlot.date"
                    flat
                    solo
                    required
                    type="date"
                    placeholder="Date"
                    hide-details="auto"
                    :showCalendarIcon="true"
                    :dateFormat="isoFormat"
                    :error-messages="formBlockTimeSlot.$getError('date')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="handleDateChange"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="3">
                  <DatetimePicker
                    flat
                    solo
                    type="time"
                    placeholder="Start"
                    hide-details
                    v-model="formBlockTimeSlot.start_time"
                    :error-messages="formBlockTimeSlot.$getError('start_time')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="handleStartChange"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="3">
                  <DatetimePicker
                    flat
                    solo
                    type="time"
                    placeholder="End"
                    hide-details
                    v-model="formBlockTimeSlot.end_time"
                    :error-messages="formBlockTimeSlot.$getError('end_time')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="handleEndChange"
                  />
                </v-col>
              </v-row>
            </v-container>
            <div class="ml-4 mt-4 red--text" v-if="newTimeSlotIsOverlap">
              Warning: This time slot conflicts with an existing time slot
            </div>
            <div class="ml-4 mt-4 red--text" v-if="!isValidTime">
              The end date should be after start date.
            </div>
            <div
              class="ml-4 mt-4 red--text"
              v-if="!isValidDate || !isValidUpcomingTime"
            >
              The start date should be today or after today.
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              :disabled="loading"
              @click="cancelDialog()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              name="submitCreateBlockSlotItem"
              :loading="formBlockTimeSlot.$busy"
              :disabled="
                loading ||
                !isValidTime ||
                !isValidDate ||
                !isValidUpcomingTime ||
                !formBlockTimeSlot.start_time ||
                !formBlockTimeSlot.end_time
              "
              @click="formBlockTimeSlot.id ? updateTimeSlot() : submitCreate()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDeleteBlockSlotTime" max-width="600px">
      <v-form
        ref="formDeleteBlockTimeSlot"
        class="faq-form__form"
        @submit.prevent="submitDeleteBlockTimeSlot"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Are you sure to delete this time slot?</span>
          </v-card-title>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitDeleteBlockTimeSlot"
              name="submitDeleteBlockTimeSlot"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Booking Schedule Type Form
 * ==================================================================================
 **/
import { mdiPencil, mdiDelete, mdiPlus, mdiEyeOff, mdiEye } from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import DatetimePicker from '@/components/fields/DatetimePicker'
import {
  convertToDayjs,
  dateOnlyFormat,
  timeOnlyFormat,
  combineDateAndTimeFromObject,
} from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
// import dayjs from 'dayjs'

export default {
  components: {
    DatetimePicker,
  },

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  props: {
    timeSlotListValue: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    const initForm = {
      id: '',
      description: '',
      date: new Date().toISOString(),
      start_time: '',
      end_time: '',
    }

    return {
      defaultFilter: 'name',
      sortOrder: false,
      icons: {
        edit: mdiPencil,
        delete: mdiDelete,
        add: mdiPlus,
        eyesOff: mdiEyeOff,
        eyesOn: mdiEye,
      },
      headers: [
        {
          text: 'Date',
          value: 'date',
        },
        {
          text: 'Start',
          value: 'start',
        },
        {
          text: 'End',
          value: 'end',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      actions: [
        {
          value: 'edit',
          label: <v-icon class="mr-2">{{ mdiPencil }}</v-icon>,
        },
        {
          value: 'delete',
          label: <v-icon class="mr-2">{{ mdiDelete }}</v-icon>,
        },
      ],
      loading: false,
      blockItems: [],
      totalItems: 10,
      selectedItem: null,
      dialogBlockSlotTime: false,
      dialogDeleteBlockSlotTime: false,
      initForm,
      formBlockTimeSlot: new Form({
        id: '',
        description: '',
        date: new Date().toISOString(),
        start_time: '',
        end_time: '',
      }),
      options: {},
      blockTimeSlotMeta: 0,
      openTimeSlotAttendees: false,
      timeSlotId: null,
      eventId: null,
      timeslotDefaultFilter: 'start_date',
      newTimeSlotIsOverlap: false,
      noDataText: 'There are no time slots',
      showAllSlots: true,
      isValidTime: true,
      isValidDate: true,
      isValidUpcomingTime: true,
    }
  },

  watch: {
    formBlockTimeSlot: {
      handler(newValue) {
        this.formBlockTimeSlot = newValue
      },
      deep: true,
    },
  },
  computed: {
    isoFormat() {
      return DATETIME_FORMAT.isoDateFormat
    },
  },
  methods: {
    addDays(dateString, days) {
      const date = new Date(dateString)
      date.setDate(date.getDate() + days)
      return date.toISOString()
    },

    addHours(dateString, hours) {
      const date = new Date(dateString)
      date.setHours(date.getHours() + hours)
      return date.toISOString()
    },

    createItem() {
      this.dialogBlockSlotTime = true
      this.selectedItem = null
      const lastTimeSlot = this.timeSlotListValue.sort(
        (a, b) => new Date(b.start_time) - new Date(a.start_time)
      )[0]
      if (lastTimeSlot) {
        this.formBlockTimeSlot = new Form({
          id: '',
          description: lastTimeSlot.description || '',
          date: this.addDays(lastTimeSlot.start_time, 7),
          start_time: this.addDays(lastTimeSlot.start_time, 7),
          end_time: this.addHours(this.addDays(lastTimeSlot.start_time, 7), 1),
        })
      } else {
        const currentDate = new Date()
        this.formBlockTimeSlot = new Form({
          id: '',
          description: '',
          date: currentDate.toISOString(),
          start_time: currentDate.toISOString(),
          end_time: this.addHours(currentDate, 1),
        })
      }
    },

    editItem(item) {
      console.log('////5')
      this.dialogBlockSlotTime = true
      this.selectedItem = item
      this.formBlockTimeSlot = new Form({ ...item, date: new Date() })
      this.formBlockTimeSlot.date = this.formatDateString(
        this.formBlockTimeSlot.start_time
      )
      this.formBlockTimeSlot.start_time = this.formatDateString(
        this.formBlockTimeSlot.start_time
      )
      this.formBlockTimeSlot.end_time = this.formatDateString(
        this.formBlockTimeSlot.end_time
      )
    },

    checkTimeSlotOverlap() {
      if (
        this.formBlockTimeSlot.date &&
        this.formBlockTimeSlot.start_time &&
        this.formBlockTimeSlot.end_time &&
        !this.selectedItem
      ) {
        this.checkExistedTimeSlotBeforeSubmit()
      } else {
        this.newTimeSlotIsOverlap = false
      }
    },

    checkValidTime() {
      // eslint-disable-next-line camelcase
      const { end_time, start_time, date } = this.formBlockTimeSlot

      // eslint-disable-next-line camelcase
      if (start_time && end_time) {
        this.isValidTime = new Date(end_time) > new Date(start_time)
      } else {
        this.isValidTime = true
      }

      // eslint-disable-next-line camelcase
      if (date && start_time) {
        this.checkValidWithDateTimeNow(start_time, date)
      } else {
        this.isValidDate = true
      }
    },

    checkValidDate() {
      // eslint-disable-next-line camelcase
      const { start_time, date } = this.formBlockTimeSlot

      if (date) {
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const selectedDate = new Date(date).setHours(0, 0, 0, 0)
        this.isValidDate = selectedDate >= currentDate
        // eslint-disable-next-line camelcase
        if (start_time) {
          this.checkValidWithDateTimeNow(start_time, date)
        }
      } else {
        this.isValidDate = true
      }
    },

    // eslint-disable-next-line camelcase
    checkValidWithDateTimeNow(start_time, date) {
      // eslint-disable-next-line camelcase
      if (date && start_time) {
        const startTime = convertToDayjs(start_time)
        const now = convertToDayjs(new Date())
        const currentDate = convertToDayjs(new Date().setHours(0, 0, 0, 0))
        const selectedDate = convertToDayjs(new Date(date).setHours(0, 0, 0, 0))

        if (selectedDate.isSame(currentDate)) {
          this.isValidUpcomingTime = this.compareTimes(startTime, now)
        } else if (selectedDate.isBefore(currentDate)) {
          this.isValidUpcomingTime = false
        } else {
          this.isValidUpcomingTime = true
        }
      } else {
        this.isValidUpcomingTime = true
      }
    },

    compareTimes(date1, date2) {
      const time1 = date1.hour() * 3600 + date1.minute() * 60
      const time2 = date2.hour() * 3600 + date2.minute() * 60
      return time1 >= time2
    },

    async checkExistedTimeSlotBeforeSubmit() {
      const startTime = combineDateAndTimeFromObject(
        this.formBlockTimeSlot.date,
        this.formBlockTimeSlot.start_time
      )
        .utc()
        .format()
      const endTime = combineDateAndTimeFromObject(
        this.formBlockTimeSlot.date,
        this.formBlockTimeSlot.end_time
      )
      const timeSlotExist = this.timeSlotListValue.some((item) => {
        return (
          new Date(item.start_time).getTime() ===
            new Date(startTime).getTime() &&
          new Date(item.end_time).getTime() === new Date(endTime).getTime()
        )
      })
      if (timeSlotExist) {
        this.newTimeSlotIsOverlap = true
      } else {
        this.newTimeSlotIsOverlap = false
      }
    },

    formatDateString(dateString) {
      const date = new Date(dateString)
      const isoString = date.toISOString()
      return isoString.replace(/\.\d{3}Z$/, '.000Z')
    },

    deleteItem(item) {
      this.dialogDeleteBlockSlotTime = true
      this.selectedItem = item
    },

    cancelDialog() {
      this.dialogBlockSlotTime = false
      this.selectedItem = null
    },
    cancelDialogDelete() {
      this.dialogDeleteBlockSlotTime = false
      this.selectedItem = null
    },
    convertToUTC(dateString) {
      const date = new Date(dateString)
      return date.toLocaleString('en-US', { timeZone: 'UTC' })
    },
    handleDateChange(newDate) {
      this.formBlockTimeSlot.date = newDate
      this.checkTimeSlotOverlap()
      this.checkValidDate()
    },
    handleStartChange(newTime) {
      this.formBlockTimeSlot.start_time = newTime
      this.checkTimeSlotOverlap()
      this.checkValidTime()
    },
    handleEndChange(newTime) {
      this.formBlockTimeSlot.end_time = newTime
      this.checkTimeSlotOverlap()
      this.checkValidTime()
    },
    formatTime(time) {
      return timeOnlyFormat(time)
    },
    formatDate(date) {
      return dateOnlyFormat(date, DATETIME_FORMAT.eventDateFormat)
    },
    closeTimeSlotAttendees() {
      this.openTimeSlotAttendees = false
      this.timeSlotId = null
      this.eventId = null
    },
    submitCreate() {
      const startTime = combineDateAndTimeFromObject(
        this.formBlockTimeSlot.date,
        this.formBlockTimeSlot.start_time
      ).toISOString()
      const endTime = combineDateAndTimeFromObject(
        this.formBlockTimeSlot.date,
        this.formBlockTimeSlot.end_time
      ).toISOString()

      const randomId = Math.floor(Math.random() * 10000)

      const newTimeSlot = {
        id: randomId,
        start_time: startTime,
        end_time: endTime,
      }

      // Emit the updated list to the parent
      this.$emit('update-time-slot-list', [
        ...this.timeSlotListValue,
        newTimeSlot,
      ])

      this.dialogBlockSlotTime = false
      this.showSnackbar('Time slot successfully created!')
    },

    updateTimeSlot() {
      const updatedTimeSlotList = this.timeSlotListValue.map((slot) => {
        if (slot.id === this.formBlockTimeSlot.id) {
          return {
            ...slot,
            start_time: this.formBlockTimeSlot.start_time,
            end_time: this.formBlockTimeSlot.end_time,
          }
        }
        return slot
      })

      this.$emit('update-time-slot-list', updatedTimeSlotList)
      this.dialogBlockSlotTime = false
      this.showSnackbar('Time slot successfully updated!')
    },

    submitDeleteBlockTimeSlot() {
      const updatedTimeSlotList = this.timeSlotListValue.filter(
        (slot) => slot.id !== this.selectedItem.id
      )

      this.$emit('update-time-slot-list', updatedTimeSlotList)
      this.dialogDeleteBlockSlotTime = false
      this.showSnackbar('Time slot successfully deleted!')
    },
  },
}
</script>

<style lang="scss" scoped>
.show-hide-slots-btn {
  display: flex;
  margin: 30px 0 0 auto;
}
</style>
